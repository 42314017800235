<template>
  <section id="daftar-rekomendasi-teknis">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-if="userData.group_id < 21"
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'status_text'">
                <b-badge
                  :variant="statusVariant(props.row.status_text).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status_text).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'nama_kegiatan'">
                <feather-icon
                  v-if="props.row.prioritas_nasional === 1"
                  class="text-warning"
                  icon="StarIcon"
                  size="14"
                />
                {{ props.row.nama_kegiatan }}
              </span>
              <span v-else-if="props.column.field === 'aktivitas'">
                <b-badge
                  :variant="activityVariant(props.row.aktivitas).class"
                  class="text-wrap mb-50"
                >
                  {{ activityVariant(props.row.aktivitas).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'total_anggaran'">
                {{ Number(props.row.total_anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                {{ props.row.last_update | moment }}
              </span>
              <span v-else-if="props.column.field === 'verifikasi_teknis'">
                {{ props.row.verifikasi_teknis | moment }}
              </span>
              <span v-else-if="props.column.field === 'asdep_app'">
                {{ props.row.asdep_app }} / {{ props.row.total_app }}
              </span>
              <span v-else-if="props.column.field === 'asdep_infra'">
                {{ props.row.asdep_infra }} / {{ props.row.total_infra }}
              </span>
              <span v-else-if="props.column.field === 'takel_app'">
                {{ props.row.takel_app }} / {{ props.row.total_app }}
              </span>
              <span v-else-if="props.column.field === 'takel_infra'">
                {{ props.row.takel_infra }} / {{ props.row.total_infra }}
              </span>
              <span v-else-if="props.column.field === 'laip_app'">
                {{ props.row.laip_app }} / {{ props.row.total_app }}
              </span>
              <span v-else-if="props.column.field === 'laip_infra'">
                {{ props.row.laip_infra }} / {{ props.row.total_infra }}
              </span>
              <span v-else-if="props.column.field === 'anggaran'">
                {{ Number(props.row.anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'is_asdep_done'">
                <b-badge
                  :variant="activityVariantNew(props.row.is_asdep_done).class"
                  class="text-wrap"
                  style="max-width:65px"
                >
                  {{ activityVariantNew(props.row.is_asdep_done).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'is_takel_done'">
                <b-badge
                  :variant="activityVariantNew(props.row.is_takel_done).class"
                  class="text-wrap"
                  style="max-width:65px"
                >
                  {{ activityVariantNew(props.row.is_takel_done).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'is_laip_done'">
                <b-badge
                  :variant="activityVariantNew(props.row.is_laip_done).class"
                  class="text-wrap"
                  style="max-width:65px"
                >
                  {{ activityVariantNew(props.row.is_laip_done).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'is_bapenas_done'">
                <b-badge
                  :variant="activityVariantNew(props.row.is_bapenas_done).class"
                  class="text-wrap"
                  style="max-width:65px"
                >
                  {{ activityVariantNew(props.row.is_bapenas_done).title }}
                </b-badge>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-rekomendasi', params: { id: props.row.data_utama_id } }"
                  :data-id="props.row.id"
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Mulai Rekomendasi Kegiatan"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="12"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  variant="primary"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Rekapitulasi Rekomendasi"
                  @click="openRekomendasi(props.row)"
                >
                  <feather-icon
                    icon="ListIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-rekomendasi"
      ref="modal-rekomendasi"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      title="Daftar Rekomendasi Awal"
    >
      <b-card-text>
        <summary-rekomendasi
          v-if="!terusReko"
          :rekap-lanjut="listRekomendasi.continue"
          :rekap-tidak="listRekomendasi.postpone"
        />
        <template
          v-if="terusReko"
        >
          <template v-if="listRekomendasi.apps.length !== 0">
            <div
              v-for="(appl, keyd) in listRekomendasi.apps"
              :key="`app-${keyd}`"
              :title="appl.nama"
            >
              <h4 class="text-primary">
                Aplikasi:
                {{ appl.nama }}
              </h4>
              <summary-rekomendasi-awal
                :app-detail="appl.recommendations"
              />
            </div>
          </template>
          <template v-if="listRekomendasi.infra.length !== 0">
            <div
              v-for="(infr, keyd) in listRekomendasi.infra"
              :key="`inf-${keyd}`"
              :title="infr.nama"
            >
              <h4 class="text-primary">
                Infrastruktur:
                {{ infr.nama }}
              </h4>
              <summary-rekomendasi-awal
                :app-detail="infr.recommendations"
              />
            </div>
          </template>
          <template v-if="listRekomendasi.datas.length !== 0">
            <div
              v-for="(infr, keyd) in listRekomendasi.datas"
              :key="`inf-${keyd}`"
              :title="infr.nama"
            >
              <h4 class="text-primary">
                Data:
                {{ infr.nama }}
              </h4>
              <summary-rekomendasi-awal
                :app-detail="infr.recommendations"
              />
            </div>
          </template>
          <template v-if="listRekomendasi.non_teknis.length !== 0">
            <div
              v-for="(infr, keyd) in listRekomendasi.non_teknis"
              :key="`inf-${keyd}`"
              :title="infr.nama"
            >
              <h4 class="text-primary">
                Non Teknis:
                {{ infr.nama }}
              </h4>
              <summary-rekomendasi-awal
                :app-detail="infr.recommendations"
              />
            </div>
          </template>
        </template>

        <b-row v-if="terusReko && userData.group_id > 59 && userData.group_id < 80 && userData.instansi_id !== 43 && (listRekomendasi.apps.length !== 0 || listRekomendasi.infra.length !== 0 || listRekomendasi.datas.length !== 0 || listRekomendasi.non_teknis.length !== 0)">
          <b-col cols="12 text-center">
            <h4 class="mt-1">
              Teruskan Rekomendasi?
            </h4>
            <template v-if="errorStat">
              <b-alert
                variant="danger"
                class="mt-2"
                show
              >
                <h4 class="alert-heading">
                  Error Found
                </h4>
                <div class="alert-body">
                  {{ errorMsg }}
                </div>
              </b-alert>
            </template>
          </b-col>
          <b-col
            cols="12"
            class="mb-1 text-center"
          >
            <template
              v-if="!dataSaved"
            >
              <b-button
                variant="outline-secondary"
                size="sm"
                class="mx-25"
                @click="$bvModal.hide('modal-rekomendasi')"
              >
                Tidak
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="mx-25"
                title="Daftar Rekomendasi"
                @click="submitRekomendasi()"
              >
                Ya, Teruskan!
              </b-button>
            </template>
            <b-button
              v-if="dataSaved"
              variant="flat-success"
              size="sm"
              class="mx-25"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="14"
              /> Data Berhasil Disimpan
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import store from '@/store'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BAlert, BRow, BBadge, BButton, BModal, BCol, BCard, BCardText,
  BFormGroup, BFormInput, VBTooltip, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/rekomendasiColumns'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import SummaryRekomendasi from '../rekomendasi/SummaryRekomendasi.vue'
import SummaryRekomendasiAwal from '../rekomendasi/SummaryRekomendasiAwal.vue'

export default {
  components: {
    BAlert,
    BButton,
    BCardText,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    SummaryRekomendasi,
    SummaryRekomendasiAwal,
    vSelect,
  },
  setup() {
    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()
    const { newColumns, subColumns } = tableColumns()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const columns = newColumns
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'modified_on',
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      columns,
      subColumns,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    moment(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      return `${diff} Hari`
    },
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 10 && role < 69 && role > 80) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      modalTitle: 'Daftar Aplikasi dan Infrastruktur',
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      rows: [],
      app_rows: [],
      searchTerm: '',
      subSearchTerm: '',
      master_aktivitas: '',
      daftarAktivitas: ['Belum Selesai', 'Selesai'],
      master_data_instansi: '',
      daftarInstansi: [],
      master_jenis_belanja_id: '',
      daftarJenisBelanja: [],
      master_jenis_pengadaan_id: '',
      daftarJenisPengadaan: [],
      master_sumber_dana_id: '',
      daftarSumberDana: [],
      masterBelanja: [],
      masterPengadaan: [],
      masterSumberDana: [],
      listRekomendasi: {
        apps: [],
        infra: [],
        datas: [],
        non_teknis: [],
      },
      submitPrep: 0,
      dataSaved: false,
      terusReko: false,
      errorStat: false,
      errorMsg: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'rekomendasi_awal' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.serverParams.sort_by = [{
      asc_desc: 'asc',
      column: 'verifikasi_teknis',
    }]
    this.doFilter()
    store.commit('app/SET_PAGE', 'rekomendasi_awal')
    this.getReference()
  },
  methods: {
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      const statusColor = {
        'Menunggu Rekomendasi': {
          title: 'Menunggu Rekomendasi',
          class: 'light-secondary',
        },
        'Sedang Rekomendasi': {
          title: 'Sedang Rekomendasi',
          class: 'light-warning',
        },
        VERIFY: {
          title: 'Menunggu Rekomendasi Sementara',
          class: 'light-secondary',
        },
      }
      if (statusColor[status]) {
        return statusColor[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    verifikasiVariant(status) {
      if (this.verificationColors[status]) {
        return this.verificationColors[status]
      }
      return {
        title: 'Menunggu Verifikasi Teknis',
        class: 'light-secondary',
      }
    },
    activityVariant(status) {
      if (this.activityColors[status]) {
        return this.activityColors[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    activityVariantNew(status) {
      if (this.activityColorsNew[status]) {
        return this.activityColorsNew[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    clearanceVariant(status) {
      if (this.clearanceColors[status]) {
        return this.clearanceColors[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    openRekomendasi(appData) {
      this.terusReko = true
      this.dataSaved = false
      this.errorStat = false
      this.errorMsg = ''
      const params = {
        token: localStorage.getItem('userToken'),
        clearance_id: appData.clearance_id,
        data_utama_id: appData.data_utama_id,
      }

      this.$http.get('/recommendation/get-app-infra-recommendations', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.submitPrep = appData
            this.listRekomendasi = res.data.data
            this.$refs['modal-rekomendasi'].show()
          }
        })
    },
    openRekomendasiApp(appData) {
      this.terusReko = false
      this.modalTitle = 'Daftar Rekomendasi Awal'

      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
        type: (appData.domain === 'Aplikasi') ? 'app' : 'infra',
      }
      if (appData.domain === 'Aplikasi') {
        params.aplikasi_id = appData.aplikasi_id
      } else {
        params.infrastruktur_id = appData.infrastruktur_id
      }
      // get all recommendations
      this.$http.get('/recommendation/get-recommendations', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-rekomendasi'].show()
          }
        })
    },
    submitRekomendasi() {
      if (this.submitPrep) {
        const formData = {
          clearance_id: this.submitPrep.clearance_id,
          data_utama_id: this.submitPrep.data_utama_id,
        }
        this.$http.post('/recommendation/submit', formData, {
          params: {
            token: localStorage.getItem('userToken'),
          },
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.dataSaved = true
              this.doFilter()

              setTimeout(() => {
                this.dataSaved = false
                this.$refs['modal-rekomendasi'].hide()
              }, 1000)
            }
          })
          .catch(error => {
            this.errorStat = true
            this.errorMsg = error.response.data.error
          })
      }
    },
    globalFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi') {
          columName = 'instansi'
        }
        filterz.push({
          column: columName,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/recommendation/my-work?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
