<template>
  <section id="daftar-surat-rekomendasi">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-if="userData.group_id < 21"
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field == 'tanggal_surat'">
                {{ props.row.tanggal_surat | formatDate }}
              </span>
              <div v-else-if="props.column.field == 'surat_id'">
                <template v-if="props.row.children.length !== 0">
                  <b-list-group
                    class="mb-1"
                    flush
                  >
                    <b-list-group-item
                      v-for="(data, index) in props.row.children"
                      :key="index"
                      class="p-25"
                    >
                      <b>{{ data.domain }}</b><br>
                      {{ data.nama_kegiatan }}
                    </b-list-group-item>
                  </b-list-group>
                </template>
              </div>
              <div v-else-if="props.column.field == 'document_uri'">
                <b-button
                  v-b-tooltip.hover
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Preview Berita Acara"
                  @click="openFile(props.row.document_uri)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="12"
                  />
                </b-button>
                <b-link
                  v-if="props.row.dirjen_sign === 1"
                  v-b-tooltip.hover
                  :href="getDoc(props.row.document_uri)"
                  class="btn btn-sm btn-success mr-25 mb-25"
                  target="_blank"
                  title="Download Berita Acara"
                >
                  <feather-icon
                    icon="DownloadCloudIcon"
                    size="12"
                  />
                </b-link>
                <b-button
                  v-if="userData.group_id < 21"
                  v-b-tooltip.hover
                  variant="danger"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Hapus Berita Acara"
                  @click="removeFile(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="12"
                  />
                </b-button>
              </div>
              <div v-else-if="props.column.field == 'dirjen_sign_1'">
                <b-button
                  v-if="userData.group_id === 42 && props.row.dirjen_sign === 0 && props.row.is_data === 0"
                  v-b-tooltip.hover
                  variant="outline-warning"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="doTte(props.row, 'DIRJEN')"
                >Berikan TTE</b-button>
                <template
                  v-else-if="props.row.is_data === 0"
                >
                  <span
                    v-if="props.row.dirjen_sign"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <div v-else-if="props.column.field == 'dirjen_sign_2'">
                <b-button
                  v-if="userData.group_id === 43 && props.row.dirjen_sign === 0 && props.row.is_data === 1"
                  v-b-tooltip.hover
                  variant="outline-warning"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="doTte(props.row, 'DIRJEN')"
                >Berikan TTE</b-button>
                <template
                  v-else-if="props.row.is_data === 1"
                >
                  <span
                    v-if="props.row.dirjen_sign"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <div v-else-if="props.column.field == 'asdep_sign'">
                <b-button
                  v-if="userData.group_id === 53 && props.row.asdep_sign === 0"
                  v-b-tooltip.hover
                  variant="outline-warning"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="doTte(props.row, 'ASDEP')"
                >Berikan TTE</b-button>
                <template
                  v-else
                >
                  <span
                    v-if="props.row.asdep_sign"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <div v-else-if="props.column.field == 'takel_sign'">
                <b-button
                  v-if="userData.group_id === 51 && props.row.takel_sign === 0 && props.row.is_data === 0"
                  v-b-tooltip.hover
                  variant="outline-warning"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="doTte(props.row, 'TAKEL')"
                >Berikan TTE</b-button>
                <template
                  v-else
                >
                  <span
                    v-if="props.row.takel_sign"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <div v-else-if="props.column.field == 'laip_sign'">
                <b-button
                  v-if="userData.group_id === 52 && props.row.laip_sign === 0 && props.row.is_data === 0"
                  v-b-tooltip.hover
                  variant="outline-warning"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="doTte(props.row, 'LAIP')"
                >Berikan TTE</b-button>
                <template
                  v-else
                >
                  <span
                    v-if="props.row.laip_sign"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <div v-else-if="props.column.field == 'ep_bapenas_sign'">
                <b-button
                  v-if="userData.group_id === 55 && props.row.ep_bapenas_sign === 0 && props.row.is_data === 1"
                  v-b-tooltip.hover
                  variant="outline-warning"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="doTte(props.row, 'SP_BAPENAS')"
                >Berikan TTE</b-button>
                <template
                  v-else
                >
                  <span
                    v-if="props.row.ep_bapenas_sign"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <div v-else-if="props.column.field == 'sp_bapenas_sign'">
                <b-button
                  v-if="userData.group_id === 54 && props.row.sp_bapenas_sign === 0 && props.row.is_data === 1"
                  v-b-tooltip.hover
                  variant="outline-warning"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="doTte(props.row, 'EP_BAPENAS')"
                >Berikan TTE</b-button>
                <template
                  v-else
                >
                  <span
                    v-if="props.row.sp_bapenas_sign"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-primary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-embed
          :source="fileSurat"
          style="height:90vh"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-tte"
      ref="modal-tte"
      hide-footer
      centered
      scrollable
      size="lg"
      title="Berikan Tanda Tangan Elektronik dengan E-Sign BSRE"
      @hidden="resetTte()"
    >
      <b-card-text>
        <form @submit.prevent="uploadTte">
          <b-row>
            <b-col
              md="12"
            >
              <b-form-group
                label="NIK"
                label-for="tte_nik"
              >
                <b-form-input
                  v-model="tte_nik"
                  name="tte_nik"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
            >
              <b-form-group
                label="Passphrase"
                label-for="tte_pwd"
              >
                <b-form-input
                  v-model="tte_pwd"
                  type="password"
                  name="tte_pwd"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="errorStat"
              cols="12"
            >
              <b-alert
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  Error Found
                </h4>
                <div class="alert-body">
                  <ul v-if="typeof errorMsg === 'object'">
                    <li
                      v-for="(item, index) in errorMsg"
                      :key="index"
                    >
                      {{ `${index}: ${item}` }}
                    </li>
                  </ul>
                  <span v-else>{{ errorMsg }}</span>
                </div>
              </b-alert>
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-button
                variant="success"
                class="mt-0 mt-md-1"
                size="md"
                type="submit"
              >
                <span>Berikan TTE</span>
              </b-button>
              <b-button
                v-if="dataSaved"
                variant="flat-success"
                size="sm"
                class="mt-0 mt-md-1 ml-50"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="14"
                /> TTE Berhasil Disimpan
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-card-text>
    </b-modal>

  </section>

</template>

<script>
import store from '@/store'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import vSelect from 'vue-select'
import {
  BRow, BButton, BModal, BCol, BCard, BInputGroup, BInputGroupAppend, BCardText, BLink,
  BFormGroup, BFormInput, VBTooltip, VBModal, BAlert, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import stColors from '@/vars/statusColors'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BLink,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    vSelect,
    VuePdfEmbed,
  },
  setup() {
    const toast = useToast()
    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'created_on',
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      toast,
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 79) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      fileSurat: '',
      isLoading: false,
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
      },
      pdfConfig: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
        },
      },
      columns: [
        {
          thClass: 'text-center',
          label: 'Pemda',
          field: 'instansi',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Instansi',
          },
        },
        {
          thClass: 'text-center',
          label: 'Nomor Berita Acara',
          field: 'nomor_surat',
        },
        {
          thClass: 'text-center',
          label: 'Tanggal Berita Acara',
          field: 'tanggal_surat',
        },
        {
          thClass: 'text-center',
          label: 'Daftar Item Belanja',
          field: 'surat_id',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Document',
          field: 'document_uri',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'PanRB',
          field: 'asdep_sign',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Kominfo Takel',
          field: 'takel_sign',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Kominfo LAIP',
          field: 'laip_sign',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Bappenas SPPEPP',
          field: 'sp_bapenas_sign',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Bappenas EP4',
          field: 'ep_bapenas_sign',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Dirjen Aptika',
          field: 'dirjen_sign_1',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Deputi Bappenas',
          field: 'dirjen_sign_2',
          sortable: false,
        },
      ],
      totalRecords: 0,
      selectPage: 1,
      rows: [],
      searchTerm: '',
      master_data_instansi: '',
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
      tteData: '',
      tteDir: '',
      tte_nik: '',
      tte_pwd: '',
    }
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'beritaAcara' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'beritaAcara')
    this.getReference()
  },
  methods: {
    downloadFile() {
      const params = this.serverParams
      this.$http.post(`/surat/export-surat-rekomendasi?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            const link = document.createElement('a')
            link.href = `${process.env.VUE_APP_API_URL}/document/excel/download/${res.data.data.filename}?token=${localStorage.getItem('userToken')}`
            link.click()
          }
          return true
        })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    removeFile(value) {
      this.$swal({
        title: 'Anda Yakin akan menghapus Berita Acara Konfirmasi?',
        text: 'Berita Acara yang telah dibuat, tidak dapat dikembalikan lagi.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya!',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          document.getElementById('loading-bg').style.display = 'block'
          const dataSurat = {
            berita_acara_pk_id: value.berita_acara_pk_id,
          }
          this.$http.post('/generate-berita-acara/delete', dataSurat, {
            params: {
              token: localStorage.getItem('userToken'),
            },
          })
            .then(res => {
              document.getElementById('loading-bg').style.display = 'none'
              if (res.data.status === 'success') {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berita Acara Konfirmasi Rekomendasi Telah Dihapus',
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                }, 4000)
                setTimeout(() => {
                  this.loadItems()
                  this.$swal.close()
                }, 1000)
              } else {
                document.getElementById('loading-bg').style.display = 'none'
                this.errorStat = true
                this.errorMsg = res.data.message
              }
            })
            .catch(error => {
              document.getElementById('loading-bg').style.display = 'none'
              this.errorStat = true
              this.errorMsg = (error.response.data.message !== 'Validation Failed') ? error.response.data.message : error.response.data.error
            })
        } else {
          this.loadItems()
          this.$swal.close()
        }
      })
    },
    openFile(value) {
      this.fileSurat = `${process.env.VUE_APP_API_URL}${value}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    doTte(appData, select) {
      this.tteData = appData
      this.tteDir = select
      this.$refs['modal-tte'].show()
    },
    resetTte() {
      this.tteData = ''
      this.tteDir = ''
    },
    uploadTte() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        berita_acara_pk_id: this.tteData.berita_acara_pk_id,
        satker: this.tteDir,
        nik: this.tte_nik,
        password: this.tte_pwd,
      }
      this.$http.post('/generate-berita-acara/e-sign', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById('loading-bg').style.display = 'none'
            this.dataSaved = true

            setTimeout(() => {
              this.dataSaved = false
              this.doFilter()
              this.$refs['modal-tte'].hide()
            }, 1000)
          } else {
            document.getElementById('loading-bg').style.display = 'none'
            this.errorStat = true
            this.errorMsg = res.data.error
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.errorStat = true
          this.errorMsg = (error.response.data.message !== 'Validation Failed') ? error.response.data.message : error.response.data.error
        })
    },
    doFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = []
      if (this.master_data_instansi) {
        filterz.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        if (valuez !== '') {
          filterz.push({
            column: key,
            value: valuez,
          })
        }
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/pk/berita-acara-list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
