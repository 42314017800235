export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'Pemda',
      field: 'instansi',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'No. Surat Permohonan',
      field: 'letter_number',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Surat',
      field: 'tanggal_surat',
      width: '125px',
    },
    {
      thClass: 'text-center',
      label: 'No. Surat',
      field: 'no_surat_kominfo',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    // {
    //   thClass: 'text-center',
    //   label: 'No. Surat MenpanRB',
    //   field: 'no_surat_panrb',
    //   filterOptions: {
    //     enabled: true,
    //     placeholder: 'Nomor Surat',
    //   },
    // },
    {
      thClass: 'text-center',
      label: 'Surat',
      field: 'url_kominfo',
      sortable: false,
    },
    // {
    //   thClass: 'text-center',
    //   label: 'Kop Surat MenpanRB',
    //   field: 'url_panrb',
    //   sortable: false,
    // },
    {
      thClass: 'text-center',
      label: 'Daftar Aplikasi / Infrastruktur',
      field: 'surat_id',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'Pemda',
      field: 'name',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Instansi',
      },
    },
    {
      thClass: 'text-center',
      label: 'No. Surat Permohonan',
      field: 'letter_number',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Surat',
      field: 'tanggal_surat',
      width: '125px',
    },
    {
      thClass: 'text-center',
      label: 'No. Surat Rekomendasi',
      field: 'no_surat_kominfo',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat',
      field: 'url_kominfo',
      width: '125px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Daftar Item Belanja',
      field: 'surat_id',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  return {
    oldColumns,
    newColumns,
  }
}
