<template>
  <section id="daftar-rekapitulasi-dirjen">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <b-row
            v-if="userData.group_id === 10"
            class="mb-1"
          >
            <b-col>
              <b-button
                variant="outline-primary"
                class="p-50"
                @click="downloadFile"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="12"
                />
                Download .xlsx
              </b-button>
            </b-col>
          </b-row>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-if="userData.group_id < 21"
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'anggaran'">
                {{ Number(props.row.anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'nama_kegiatan'">
                <feather-icon
                  v-if="props.row.prioritas_nasional === 1"
                  class="text-warning"
                  icon="StarIcon"
                  size="14"
                />
                {{ props.row.nama_kegiatan }}
              </span>
              <span v-else-if="props.column.field === 'nama'">
                <b-badge
                  variant="light-info"
                  class="text-wrap mb-50"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                {{ props.row.last_update | moment }}
              </span>
              <!-- Status Asdep -->
              <span v-else-if="props.column.field === 'deputi_status'">
                <b-button
                  v-if="props.row.deputi_status !== 'Belum'"
                  v-b-tooltip.hover
                  :variant="(props.row.deputi_status === 'Dilanjutkan') ? 'info' : 'danger'"
                  size="sm"
                  class="text-wrap"
                  style="max-width:110px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasiDirektur(props.row, 'MENPAN', props.row.deputi_status)"
                >
                  {{ props.row.deputi_status }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  size="sm"
                  disabled
                >Belum</b-button>
              </span>
              <!-- Status Takel -->
              <span v-else-if="props.column.field === 'dirjen_status'">
                <b-button
                  v-if="props.row.dirjen_status !== 'Belum'"
                  v-b-tooltip.hover
                  :variant="(props.row.dirjen_status === 'Dilanjutkan') ? 'info' : 'danger'"
                  size="sm"
                  class="text-wrap"
                  style="max-width:110px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasiDirektur(props.row, 'KOMINFO', props.row.dirjen_status)"
                >
                  {{ props.row.dirjen_status }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  size="sm"
                  disabled
                >Belum</b-button>
              </span>
              <!-- Status Bappenas -->
              <span v-else-if="props.column.field === 'bapenas_status'">
                <b-button
                  v-if="props.row.bapenas_status !== 'Belum'"
                  v-b-tooltip.hover
                  :variant="(props.row.bapenas_status === 'Dilanjutkan') ? 'info' : 'danger'"
                  size="sm"
                  class="text-wrap"
                  style="max-width:110px"
                  title="Daftar Rekomendasi"
                  @click="openRekomendasiDirektur(props.row, 'KOMINFO', props.row.bapenas_status)"
                >
                  {{ props.row.bapenas_status }}
                </b-button>
                <b-button
                  v-else
                  variant="secondary"
                  size="sm"
                  disabled
                >Belum</b-button>
              </span>
              <!-- Lihat Rekomendasi Awal -->
              <span v-else-if="props.column.field == 'rekom_awal'">
                <summary-rekomendasi-dirjen
                  v-if="props.row.rekom_awal !== 'Tidak Ada Rekomendasi'"
                  :rekap-detail="props.row.rekom_awal"
                />
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  variant="primary"
                  size="sm"
                  class="mr-25 mb-25"
                  target="_blank"
                  title="Detail Pengadaan"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="12"
                  /></b-button>
                <b-button
                  v-b-tooltip.hover
                  variant="secondary"
                  size="sm"
                  class="mr-25 mb-25"
                  title="File KAK"
                  @click="openFile(props.row.kak_uri)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileKak"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-rekomendasi-dirjen"
      ref="modal-rekomendasi-dirjen"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <b-list-group
          class="mb-1"
          flush
        >
          <b-list-group-item
            v-for="(data, index) in listRekomendasiDirjen"
            :key="index"
            class="d-flex justify-content-between"
          >
            {{ data }}
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-rekomendasi"
      ref="modal-rekomendasi"
      hide-footer
      centered
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <summary-rekomendasi
          :rekap-lanjut="listRekomendasi.continue"
          :rekap-tidak="listRekomendasi.postpone"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      ok-only
      ok-variant="warning"
      ok-title="Ok"
      modal-class="modal-warning"
      centered
      title="Attention!"
    >
      <b-card-text>
        {{ alertText }}
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import store from '@/store'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BRow, BButton, BModal, BCol, BCard, BCardText, BAlert,
  BFormGroup, BFormInput, VBTooltip, VBModal,
  BListGroup, BListGroupItem, BBadge, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/rekapitulasiEs1Columns'

import VuePdfApp from 'vue-pdf-app'
import SummaryRekomendasi from '../rekomendasi/SummaryRekomendasi.vue'
import SummaryRekomendasiDirjen from '../rekomendasi/SummaryRekomendasiDirjen.vue'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCardText,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    SummaryRekomendasi,
    SummaryRekomendasiDirjen,
    VuePdfApp,
    vSelect,
  },
  setup() {
    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()
    const { newColumns, subColumns } = tableColumns()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const baseColumns = newColumns
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'last_update',
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      baseColumns,
      subColumns,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    moment(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      return `${diff} Hari`
    },
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 49) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      alertText: '',
      fileKak: '',
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      dataProgram: '',
      modalTitle: '',
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      columns: [],
      rows: [],
      app_rows: [],
      searchTerm: '',
      subSearchTerm: '',
      tambahRekomendasi: '',
      listRekomendasi: {},
      listRekomendasiDirjen: [],
      textRekomendasi: {
        program: {},
        mode: '',
        selectmode: '',
        selectval: '',
        daftar: [],
        select: [],
        note: '',
      },
      errorStat: false,
      errorMsg: '',
      dataRekoLanjut: [],
      dataRekoTidak: [],
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      prefix: '',
      nama_kegiatan: '',
      nama_app_infra: '',
      listRekoDir: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('instansi')) {
      this.prefix = localStorage.getItem('instansi')
      this.baseColumns[0].filterOptions.filterValue = this.prefix
    }
    if (localStorage.getItem('nama_kegiatan')) {
      this.nama_kegiatan = localStorage.getItem('nama_kegiatan')
      this.baseColumns[1].filterOptions.filterValue = this.nama_kegiatan
    }
    if (localStorage.getItem('nama_app_infra')) {
      this.nama_app_infra = localStorage.getItem('nama_app_infra')
      this.baseColumns[2].filterOptions.filterValue = this.nama_app_infra
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    this.columns = this.baseColumns
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'rekapES1' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'rekapES1')
    this.getReference()
  },
  methods: {
    downloadFile() {
      const params = this.serverParams
      this.$http.post(`/dirjen/export?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            const link = document.createElement('a')
            link.href = `${process.env.VUE_APP_API_URL}/document/excel/download/${res.data.data.filename}?token=${localStorage.getItem('userToken')}`
            link.click()
          }
          return true
        })
    },
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      if (this.clearanceColors[status]) {
        return this.clearanceColors[status]
      }
      return {
        title: status,
        class: 'outlne-info',
      }
    },
    globalFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.prefix !== '') {
        params.push({
          column: 'instansi',
          value: String(this.prefix),
        })
        localStorage.setItem('instansi', this.prefix)
      } else {
        localStorage.removeItem('instansi')
      }
      if (this.nama_kegiatan !== '') {
        params.push({
          column: 'nama_kegiatan',
          value: this.nama_kegiatan,
        })
        localStorage.setItem('nama_kegiatan', this.nama_kegiatan)
      } else {
        localStorage.removeItem('nama_kegiatan')
      }
      if (this.nama_app_infra !== '') {
        params.push({
          column: 'nama_app_infra',
          value: this.nama_app_infra,
        })
        localStorage.setItem('nama_app_infra', this.nama_app_infra)
      } else {
        localStorage.removeItem('nama_app_infra')
      }
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.doFilter()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.doFilter()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        let columName = param.field
        if (param.field === 'instansi') {
          columName = 'instansi'
        } else if (param.field === 'nama') {
          columName = 'nama_app_infra'
        } else if (param.field === 'anggaran') {
          columName = 'total_anggaran'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.doFilter()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi') {
          columName = 'instansi'
        } else if (key === 'nama') {
          columName = 'nama_app_infra'
        }
        this[columName] = valuez
        filterz.push({
          column: columName,
          value: valuez,
        })
        if (valuez !== '') {
          localStorage.setItem(columName, valuez)
        } else {
          localStorage.removeItem(columName)
        }
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/dirjen/done?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            res.data.data.data.map(value => {
              const rekodata = value
              if (this.userData.group_id === 42 && rekodata.dirjen_status !== 'Belum') {
                rekodata.mode = (rekodata.dirjen_status === 'Dilanjutkan') ? 'yes' : 'no' // dirjen aptika
              } else if (this.userData.group_id === 41 && rekodata.deputi_status !== 'Belum') {
                rekodata.mode = (rekodata.deputi_status === 'Dilanjutkan') ? 'yes' : 'no' // laip
              }
              this.rows.push(rekodata)
              return rekodata
            })
            // this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    getListRekomendasi(values) {
      this.rows = []
      this.$http.post(`/dirjen/done?token=${localStorage.getItem('userToken')}`, values)
        .then(res => {
          res.data.data.map(value => {
            const rekodata = value
            if (this.userData.group_id === 42 && rekodata.dirjen_status !== 'Belum') {
              rekodata.mode = (rekodata.dirjen_status === 'Dilanjutkan') ? 'yes' : 'no' // dirjen aptika
            } else if (this.userData.group_id === 41 && rekodata.deputi_status !== 'Belum') {
              rekodata.mode = (rekodata.deputi_status === 'Dilanjutkan') ? 'yes' : 'no' // laip
            }
            this.rows.push(rekodata)
            return rekodata
          })
        })
    },
    openFile(value) {
      this.fileKak = `${process.env.VUE_APP_API_URL}${value}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    openRekomendasi(appData) {
      this.listRekomendasi = {}
      this.modalTitle = 'Daftar Rekomendasi Tim Teknis'

      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
      }
      if (appData.domain === 'APLIKASI') {
        params.type = 'app'
        params.aplikasi_id = appData.id
      } else if (appData.domain === 'INFRASTRUKTUR') {
        params.type = 'infra'
        params.infrastruktur_id = appData.id
      }
      if (appData.aplikasi_id) {
        params.type = 'app'
        params.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        params.type = 'infra'
        params.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        params.type = 'data'
        params.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        params.type = 'non-teknis'
        params.non_teknis_id = appData.non_teknis_id
      }
      this.$http.get('/recommendation/get-rekomendasi-sementara', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-rekomendasi'].show()
          }
        })
    },
    openRekomendasiDirektur(appData, direktorate, label) {
      this.listRekomendasiDirjen = []
      this.modalTitle = `Rekomendasi Dirjen : ${label}`

      const formData = {
        domain: appData.domain,
        kementerian: direktorate,
      }
      if (appData.data_id) {
        formData.domain = appData.domain
        formData.app_infra_id = appData.data_id
      } else if (appData.aplikasi_id) {
        formData.domain = 'APLIKASI'
        formData.app_infra_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        formData.domain = 'INFRASTRUKTUR'
        formData.app_infra_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        formData.domain = 'DATA'
        formData.app_infra_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        formData.domain = 'NON-TEKNIS'
        formData.app_infra_id = appData.non_teknis_id
      }
      this.$http.post('/dirjen/view', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasiDirjen = res.data.data
            this.$refs['modal-rekomendasi-dirjen'].show()
          }
        })
    },
    updateResult() {
      this.$refs['modal-tambah-rekomendasi'].hide()
      this.$refs['modal-confirm'].hide()
      this.doFilter()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
