export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'Tanggal Surat',
      field: 'tanggal_surat',
      width: '125px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'No. Surat',
      field: 'no_surat_kominfo',
      sortable: false,
    },
    // {
    //   thClass: 'text-center',
    //   label: 'No. Surat PanRB',
    //   field: 'no_surat_panrb',
    //   sortable: false,
    // },
    {
      thClass: 'text-center',
      label: 'Kop Surat',
      field: 'url_kominfo',
      width: '125px',
      sortable: false,
    },
    // {
    //   thClass: 'text-center',
    //   label: 'Kop Surat MenpanRB',
    //   field: 'url_panrb',
    //   width: '125px',
    //   sortable: false,
    // },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'Tanggal Surat',
      field: 'tanggal_surat',
      width: '125px',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'No. Surat Rekomendasi',
      field: 'no_surat_kominfo',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat',
      field: 'url_kominfo',
      width: '250px',
      sortable: false,
    },
    // {
    //   thClass: 'text-center',
    //   label: 'Kop Surat Bappenas',
    //   field: 'url_bapenas',
    //   width: '125px',
    //   sortable: false,
    // },
  ]

  return {
    oldColumns,
    newColumns,
  }
}
