<template>
  <section id="buat-berita-acara-konfirmasi">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          v-if="show"
        >
          <validation-observer
            ref="formSurat"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Berita Acara Konfirmasi Rekomendasi
                </h5>
              </b-col>
              <b-col md="12">
                <b-form-group
                  v-b-tooltip.hover.top="'Lembaga'"
                  label="Lembaga"
                  label-for="vi-instansi_name"
                >
                  <b-form-input
                    id="vi-instansi_name"
                    v-model="instansi_name"
                    required
                    disabled
                    name="instansi_name"
                    placeholder="Nama Lembaga"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Tujuan Surat Berita Acara Konfirmasi Rekomendasi Sementara'"
                  label="Tujuan Surat Berita Acara"
                  label-for="vi-tujuan_surat"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tujuan Surat Berita Acara"
                    rules="required"
                  >
                    <v-select
                      id="vi-signed"
                      v-model="tujuan_surat"
                      name="tujuan_surat"
                      :options="signed"
                      placeholder="Tujuan Surat Berita Acara"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Nomor Surat Berita Acara Konfirmasi Rekomendasi Sementara'"
                  label="Nomor Surat Berita Acara"
                  label-for="vi-nomor_surat"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor Surat Berita Acara"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-nomor_surat"
                      v-model="nomor_surat"
                      required
                      name="nomor_surat"
                      placeholder="Nomor Surat Berita Acara"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Tanggal Pembahasan Konfirmasi Rekomendasi Sementara Dilaksanakan'"
                  label="Tanggal Pembahasan"
                  label-for="vi-pembahasan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Pembahasan"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="vi-pembahasan"
                      v-model="pembahasan_date"
                      required
                      name="pembahasan"
                      placeholder="Tanggal Pembahasan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  v-b-tooltip.hover.top="'Tanggal Berita Acara Dibuat'"
                  label="Tanggal Berita Acara"
                  label-for="vi-surat_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Berita Acara"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="vi-surat_date"
                      v-model="surat_date"
                      required
                      name="surat_date"
                      placeholder="Tanggal Berita Acara"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- table -->
              <b-col cols="12">
                <h6>Daftar Item Belanja Dalam Berita Acara</h6>
                <vue-good-table
                  class="mt-1"
                  :columns="columns2"
                  :rows="rowSurat"
                  :pagination-options="{
                    enabled: false,
                  }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'nama_pengadaan'"
                      class="text-wrap"
                    >
                      <b-badge
                        variant="light-secondary"
                        class="mb-50 text-wrap"
                      >
                        {{ props.row.jenis_pengadaan }}
                      </b-badge>
                      <span class="d-block">
                        {{ props.row.nama_pengadaan }}
                      </span>
                    </span>
                    <span v-else-if="props.column.field === 'total_anggaran'">
                      {{ Number(props.row.total_anggaran).toLocaleString() }}
                    </span>
                    <div v-else-if="props.column.field == 'rekomendasi'">
                      <div>
                        <h5>{{ props.row.status }}</h5>
                        <p>
                          {{ props.row.rekomendasi }}
                        </p>
                      </div>
                    </div>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        variant="warning"
                        size="sm"
                        class="m-25"
                        @click="removeFromSurat(props.row, props.index)"
                      >
                        Hapus Dari Berita Acara
                      </b-button>
                    </span>

                  </template>
                </vue-good-table>
              </b-col>
              <b-col
                v-if="errorStat"
                cols="12"
                class="mt-1"
              >
                <b-alert
                  variant="danger"
                  show
                >
                  <div class="alert-body">
                    Error Found: {{ errorMsg }}
                  </div>
                </b-alert>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-button
                  type="submit"
                  variant="success"
                  size="sm"
                  @click.prevent="simpanSurat"
                >
                  Buat Berita Acara
                </b-button>
                <b-button
                  v-if="dataSaved"
                  variant="flat-success"
                  size="sm"
                  class="mt-0 ml-50"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                  /> Berita Acara Berhasil Disimpan
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <!-- table -->
          <h4 class="mt-2">
            Daftar Item Belanja Dalam Proses Konfirmasi Rekomendasi
          </h4>
          <vue-good-table
            class="mt-2"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'nama_pengadaan'"
                class="text-wrap"
              >
                <b-badge
                  variant="light-secondary"
                  class="mb-50 text-wrap"
                >
                  {{ props.row.jenis_pengadaan }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama_pengadaan }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'total_anggaran'">
                {{ Number(props.row.total_anggaran).toLocaleString() }}
              </span>
              <div v-else-if="props.column.field == 'rekomendasi'">
                <div>
                  <h5>{{ props.row.status }}</h5>
                  <p>
                    {{ props.row.rekomendasi }}
                  </p>
                </div>
              </div>
              <span v-else-if="props.column.field === 'action'">
                <b-badge
                  v-if="props.row.add_berita_acara !== 'Belum'"
                  variant="light-warning"
                  class="mb-50"
                >Sudah pernah ditambahkan<br>di surat sebelumnya
                </b-badge>
                <b-button
                  variant="primary"
                  size="sm"
                  class="m-25"
                  @click="addToSurat(props.row, props.index)"
                >
                  Tambah ke Berita Acara
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
        <template v-else>
          <div
            cols="12"
            class="mt-1"
          >
            <b-alert
              variant="danger"
              show
            >
              <div class="alert-body">
                Tidak ada Data untuk pembuatan Berita Acara Konfirmasi
              </div>
            </b-alert>
          </div>
        </template>
      </b-col>
      <!-- post -->

    </b-row>

  </section>

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BBadge, BRow, BFormDatepicker, BButton, BCol, BCard, BPagination, BFormGroup,
  BFormInput, BFormSelect, VBTooltip, VBModal, BAlert,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import moment from 'moment'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BAlert,
    BFormDatepicker,
    BButton,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 11 || role > 79) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      yearBudget: localStorage.getItem('tahunAnggaran'),
      required,
      today,
      show: false,
      userData: getUserData(),
      instansi_id: '',
      jenis_ba: '',
      clearanceData: '',
      listClearance: [],
      pageLength: 10,
      columns: [
        {
          thClass: 'text-center',
          label: 'No',
          field: 'id',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Item Belanja',
          field: 'nama_pengadaan',
          width: '200px',
        },
        {
          thClass: 'text-center',
          label: 'Total Anggaran',
          field: 'total_anggaran',
          width: '200px',
          type: 'number',
        },
        {
          thClass: 'text-center',
          label: 'Rekomendasi & Catatan',
          field: 'rekomendasi',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      columns2: [
        {
          thClass: 'text-center',
          label: 'No',
          field: 'id',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Item Belanja',
          field: 'nama_pengadaan',
          width: '200px',
        },
        {
          thClass: 'text-center',
          label: 'Total Anggaran',
          field: 'total_anggaran',
          width: '200px',
          type: 'number',
        },
        {
          thClass: 'text-center',
          label: 'Rekomendasi & Catatan',
          field: 'rekomendasi',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      profileData: {},
      signed: [],
      rowSurat: [],
      appList: [],
      infraList: [],
      dataList: [],
      nonList: [],
      instansi_name: '',
      tujuan_surat: '',
      nomor_surat: '',
      pembahasan_date: '',
      surat_date: '',
      number: 0,
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
    }
  },
  created() {
    this.instansi_id = this.$route.params.id
    this.jenis_ba = Number(this.$route.params.jenis)
    this.surat_date = moment(new Date(this.today)).format('YYYY-MM-DD')
    this.$http.get('/generate-berita-acara/pk-data', {
      params: {
        token: localStorage.getItem('userToken'),
        instansi_id: this.$route.params.id,
        is_data: (this.jenis_ba === 1) ? 1 : 0,
        tahun_anggaran: this.yearBudget,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.instansi_name = res.data.data.instansi
          this.listClearance = res.data.data.pk_data
          this.signed = res.data.data.signed_by
          this.prepData()
        }
      })
  },
  methods: {
    addToSurat(value, id) {
      this.rowSurat.push(value)
      this.$delete(this.rows, id)
      if (value.aplikasi_id) {
        this.appList.push(value.aplikasi_id)
      }
      if (value.infrastruktur_id) {
        this.infraList.push(value.infrastruktur_id)
      }
      if (value.data_informasi_id) {
        this.dataList.push(value.data_informasi_id)
      }
      if (value.non_teknis_id) {
        this.nonList.push(value.non_teknis_id)
      }
    },
    removeFromSurat(value, id) {
      this.rows.push(value)
      this.$delete(this.rowSurat, id)
      if (value.aplikasi_id) {
        this.$delete(this.appList, this.appList.indexOf(value.aplikasi_id))
      }
      if (value.infrastruktur_id) {
        this.$delete(this.infraList, this.infraList.indexOf(value.infrastruktur_id))
      }
      if (value.data_informasi_id) {
        this.$delete(this.dataList, this.dataList.indexOf(value.data_informasi_id))
      }
      if (value.non_teknis_id) {
        this.$delete(this.nonList, this.nonList.indexOf(value.non_teknis_id))
      }
    },
    simpanSurat() {
      this.$refs.formSurat.validate()
        .then(success => {
          if (success) {
            if (this.rowSurat.length === 0) {
              this.errorStat = true
              this.errorMsg = 'Data Aplikasi / Infrastruktur Yang Direkomendasikan Tidak Boleh Kosong'
            } else {
              this.submitSurat()
            }
          } else {
            this.errorStat = true
            this.errorMsg = 'Terdapat Kesalahan Validasi, Periksa Input Anda'
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.message
        })
    },
    submitSurat() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        instansi_id: this.instansi_id,
        penerima: this.tujuan_surat,
        tahun_anggaran: this.yearBudget,
        nomor_surat_ba: this.nomor_surat,
        tanggal_pembahasan: this.pembahasan_date,
        tanggal_surat: this.surat_date,
        is_data: (this.jenis_ba === 1) ? 1 : 0,
      }
      if (this.appList.length !== 0) {
        params.app_list = this.appList
      }
      if (this.infraList.length !== 0) {
        params.infra_list = this.infraList
      }
      if (this.dataList.length !== 0) {
        params.data_list = this.dataList
      }
      if (this.nonList.length !== 0) {
        params.non_teknis_list = this.nonList
      }

      this.$http.post(`/generate-berita-acara?token=${localStorage.getItem('userToken')}`, params)
        .then(resp => {
          if (resp.data.status === 'success') {
            document.getElementById('loading-bg').style.display = 'none'
            this.dataSaved = true
            this.errorStat = false
            this.errorMsg = ''

            setTimeout(() => {
              this.dataSaved = false
              this.$router.replace({ name: 'berita-acara-konfirmasi' })
            }, 2000)
          } else {
            this.errorStat = true
            this.errorMsg = (resp.data.error) ? resp.data.error : resp.data.message
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = (error.response.data.error) ? error.response.data.error : error.response.data.message
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    prepData() {
      this.rows = []
      if (this.listClearance.length !== 0) {
        this.listClearance.map(apps => {
          this.number += 1
          const appData = apps
          appData.id = this.number
          this.rows.push(appData)
          return true
        })
        this.show = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
