export default [
  {
    title: 'Dashboard',
    icon: 'PieChartIcon',
    route: 'dashboard',
    minAccess: 1,
    maxAccess: 1001,
    except: 0,
    exceptA: 0,
  },
  // {
  //   title: 'Pemda',
  //   icon: 'HomeIcon',
  //   route: 'pengajuan-instansi',
  //   minAccess: 1,
  //   maxAccess: 1001,
  //   except: 100,
  //   exceptA: 101,
  // },
  {
    title: 'Pengajuan',
    icon: 'InboxIcon',
    route: 'pengajuan-surat',
    minAccess: 99,
    maxAccess: 110,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Verifikasi Teknis',
    icon: 'FileTextIcon',
    route: 'verifikasi-teknis',
    minAccess: 69,
    maxAccess: 79,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Rekomendasi Teknis',
    icon: 'ClipboardIcon',
    route: 'rekomendasi-awal',
    minAccess: 59,
    maxAccess: 81,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Rekomendasi Pimpinan',
    icon: 'CheckCircleIcon',
    route: 'persetujuan-rekomendasi-pimpinan',
    minAccess: 49,
    maxAccess: 60,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Rekap Rekomendasi Pimpinan',
    icon: 'ListIcon',
    route: 'rekapitulasi-rekomendasi-pimpinan',
    minAccess: 39,
    maxAccess: 60,
    except: 1000,
    exceptA: 0,
  },
  // {
  //   title: 'Konfirmasi Rekomendasi',
  //   icon: 'FlagIcon',
  //   route: 'rekap-konfirmasi-rekomendasi',
  //   minAccess: 39,
  //   maxAccess: 81,
  //   except: 1000,
  //   exceptA: 0,
  // },
  // {
  //   title: 'Berita Acara Konfirmasi',
  //   icon: 'BookIcon',
  //   route: 'berita-acara-konfirmasi',
  //   minAccess: 39,
  //   maxAccess: 60,
  //   except: 1000,
  //   exceptA: 0,
  // },
  {
    title: 'Review Rekomendasi Sementara',
    icon: 'CheckCircleIcon',
    route: 'persetujuan-review-rekomendasi',
    minAccess: 39,
    maxAccess: 49,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Rekap Review Rekomendasi Sementara',
    icon: 'ListIcon',
    route: 'rekapitulasi-review-rekomendasi',
    minAccess: 39,
    maxAccess: 49,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Penerbitan Surat Rekomendasi',
    icon: 'SendIcon',
    route: 'penerbitan-surat',
    minAccess: 39,
    maxAccess: 79,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Surat Rekomendasi',
    icon: 'MailIcon',
    route: 'surat-rekomendasi',
    minAccess: 39,
    maxAccess: 60,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Antrian',
    icon: 'CalendarIcon',
    route: 'daftar-antrian',
    minAccess: 39,
    maxAccess: 79,
    except: 1000,
    exceptA: 0,
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    minAccess: 0,
    maxAccess: 21,
    except: 1000,
    exceptA: 0,
    children: [
      {
        title: 'Arsitektur SPBE',
        icon: 'MenuIcon',
        children: [
          {
            title: 'Proses Bisnis',
            route: 'master-domain-proses-bisnis',
          },
          {
            title: 'Layanan',
            route: 'master-domain-layanan',
          },
          {
            title: 'Data dan Informasi',
            route: 'master-domain-data-informasi',
          },
          {
            title: 'Aplikasi',
            route: 'master-domain-aplikasi',
          },
          {
            title: 'Infrastruktur',
            route: 'master-domain-infrastruktur',
          },
          {
            title: 'Keamanan',
            route: 'master-domain-keamanan',
          },
        ],
      },
      {
        title: 'Rekomendasi',
        icon: 'MenuIcon',
        children: [
          {
            title: 'Proses Bisnis',
            route: 'master-template-bisnis',
          },
          {
            title: 'Dokumen',
            route: 'master-template-dokumen',
          },
          {
            title: 'Layanan',
            route: 'master-template-layanan',
          },
          {
            title: 'Data dan Informasi',
            route: 'master-template-data-informasi',
          },
          {
            title: 'Aplikasi',
            route: 'master-template-aplikasi',
          },
          {
            title: 'Infrastruktur',
            route: 'master-template-infrastruktur',
          },
          {
            title: 'Keamanan',
            route: 'master-template-keamanan',
          },
          {
            title: 'Non Teknis',
            route: 'master-template-non-teknis',
          },
        ],
      },
      {
        title: 'Klasifikasi',
        icon: 'MenuIcon',
        children: [
          {
            title: 'Kriteria Belanja',
            route: 'master-group-pengadaan',
          },
          {
            title: 'Jenis Belanja',
            route: 'master-group-belanja',
          },
          {
            title: 'Sumber Dana',
            route: 'master-group-sumber-dana',
          },
          {
            title: 'Status Kegiatan',
            route: 'master-status-kegiatan',
          },
        ],
      },
      {
        title: 'Users Manager',
        icon: 'UsersIcon',
        route: 'master-user',
        // children: [
        //   {
        //     title: 'Users',
        //     icon: 'UserIcon',
        //     route: 'master-user',
        //   },
        //   // {
        //   //   title: 'Accounts',
        //   //   route: 'master-account',
        //   // },
        //   // {
        //   //   title: 'Roles',
        //   //   route: 'master-roles',
        //   // },
        // ],
      },
      {
        title: 'Reports',
        icon: 'FileTextIcon',
        route: 'pages-reports',
      },
      // {
      //   title: 'BSrE Log',
      //   icon: 'ShieldIcon',
      //   route: 'log-sistem',
      // },
    ],
  },
]
