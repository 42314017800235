<template>
  <div id="view-detail-kegiatan">
    <b-form id="formVerifikasi">
      <app-collapse type="margin">
        <app-collapse-item
          id="dokumen"
          title="Surat Permohonan"
          :is-visible="true"
        >
          <alert-module
            v-if="setStep === 'review' && dokumen.is_verified"
            :doc-data="dokumen"
          />

          <model-dokumen
            :data-doc="dokumen"
            @view-file="viewFile"
          />

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
             v-if="userData.group_id > 59 && userData.group_id < 80"
              :data-utama="utama"
              :status-awal="dokumen"
              :action-step="verifikasi"
              step-verifikasi="dokumen"
            />
            <alert-module
              v-else
              :doc-data="dokumen"
            />
          </template>

          <template
            v-else-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-umum
              v-if="dataRekoSet && userData.group_id > 59 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="dokumen"
              step-rekomendasi="dokumen"
            />
            <summary-rekomendasi-ketua
              v-else-if="dataRekoSet && userData.group_id > 69 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="dokumen"
              step-rekomendasi="dokumen"
            />
          </template>
        </app-collapse-item>

        <app-collapse-item
          v-for="(infs, index) in infra"
          :key="`inf-${index}`"
          :title="`Infrastruktur: ${infs.nama}`"
          :is-visible="true"
        >
          <alert-module
            v-if="setStep === 'review' && infs.is_verified"
            :doc-data="infs"
          />

          <model-infra
            :data-infra="infs"
            @view-file="viewFile"
          />

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
             v-if="userData.group_id > 59 && userData.group_id < 80"
              :data-utama="utama"
              :status-awal="infs"
              :action-step="verifikasi"
              step-verifikasi="infrastruktur"
            />
            <alert-module
              v-else
              :doc-data="infs"
            />
          </template>

          <template
            v-else-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-clearance
              v-if="dataRekoSet && userData.group_id > 59 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="infs"
              :component-id="infs.id"
              step-rekomendasi="infrastruktur"
            />
            <summary-rekomendasi-ketua-clearance
              v-else-if="dataRekoSet && userData.group_id > 69 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="infs"
              :component-id="infs.id"
              step-rekomendasi="infrastruktur"
            />
          </template>
        </app-collapse-item>

        <app-collapse-item
          title="Referensi Program (Proses Bisnis)"
          :is-visible="true"
        >
          <app-collapse type="margin">
            <app-collapse-item
              v-for="(apps, index) in program"
              :key="`app-${index}`"
              :title="`${apps.nama_urusan} - ${apps.nama_program}`"
              :is-visible="true"
            >
              <alert-module
                v-if="setStep === 'review' && apps.is_verified"
                :doc-data="apps"
              />

              <model-probis
                :data-doc="apps"
              />

              <template
                v-if="setStep === 'verifikasi'"
              >
                <modul-verifikasi
                 v-if="userData.group_id > 59 && userData.group_id < 80"
                  :data-utama="utama"
                  :status-awal="apps"
                  :action-step="verifikasi"
                  step-verifikasi="program"
                />
                <alert-module
                  v-else
                  :doc-data="apps"
                />
              </template>

              <template
                v-if="setStep === 'rekomendasi'"
              >
                <modul-rekomendasi-umum
                  v-if="dataRekoSet && userData.group_id > 59 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="apps"
                  step-rekomendasi="program"
                />
                <summary-rekomendasi-ketua
                  v-else-if="dataRekoSet && userData.group_id > 69 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="apps"
                  step-rekomendasi="program"
                />
              </template>
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>

        <app-collapse-item
          title="Referensi Aplikasi"
          :is-visible="true"
        >
          <app-collapse type="margin">
            <app-collapse-item
              v-for="(apps, index) in aplikasi"
              :key="`app-${index}`"
              :title="`Aplikasi: ${apps.nama}`"
              :is-visible="true"
            >
              <alert-module
                v-if="setStep === 'review' && apps.is_verified"
                :doc-data="apps"
              />

              <model-aplikasi
                :data-aplikasi="apps"
                @view-file="viewFile"
              />

              <template
                v-if="setStep === 'verifikasi'"
              >
                <modul-verifikasi
                 v-if="userData.group_id > 59 && userData.group_id < 80"
                  :data-utama="utama"
                  :status-awal="apps"
                  :action-step="verifikasi"
                  step-verifikasi="aplikasi"
                />
                <alert-module
                  v-else
                  :doc-data="apps"
                />
              </template>

              <template
                v-else-if="setStep === 'rekomendasi'"
              >
                <modul-rekomendasi-umum
                  v-if="dataRekoSet && userData.group_id > 59 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="apps"
                  :component-id="apps.id"
                  step-rekomendasi="aplikasi"
                />
                <summary-rekomendasi-ketua-clearance
                  v-else-if="dataRekoSet && userData.group_id > 69 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="apps"
                  :component-id="apps.id"
                  step-rekomendasi="aplikasi"
                />
              </template>
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>

        <app-collapse-item
          v-if="layanan"
          title="Referensi Layanan"
          :is-visible="true"
        >
          <app-collapse type="margin">
            <app-collapse-item
              v-for="(apps, index) in layanan"
              :key="`app-${index}`"
              :title="apps.nama_layanan"
              :is-visible="true"
            >
              <alert-module
                v-if="setStep === 'review' && apps.is_verified"
                :doc-data="apps"
              />

              <model-layanan
                :data-doc="apps"
              />

              <template
                v-if="setStep === 'verifikasi'"
              >
                <modul-verifikasi
                 v-if="userData.group_id > 59 && userData.group_id < 80"
                  :data-utama="utama"
                  :status-awal="apps"
                  :action-step="verifikasi"
                  step-verifikasi="layanan"
                />
                <alert-module
                  v-else
                  :doc-data="apps"
                />
              </template>

              <template
                v-else-if="setStep === 'rekomendasi'"
              >
                <modul-rekomendasi-umum
                  v-if="dataRekoSet && userData.group_id > 59 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="apps"
                  step-rekomendasi="layanan"
                />
                <summary-rekomendasi-ketua
                  v-else-if="dataRekoSet && userData.group_id > 69 && userData.group_id < 80"
                  :data-utama="utama"
                  :detail-program="apps"
                  step-rekomendasi="layanan"
                />
              </template>

            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>

        <app-collapse-item
          v-if="keamanan"
          title="Keamanan"
          :is-visible="true"
        >
          <alert-module
            v-if="setStep === 'review' && keamanan.is_verified"
            :doc-data="keamanan"
          />

          <model-keamanan
            :data-doc="keamanan"
          />

          <template
            v-if="setStep === 'verifikasi'"
          >
            <modul-verifikasi
             v-if="userData.group_id > 59 && userData.group_id < 80"
              :data-utama="utama"
              :status-awal="keamanan"
              :action-step="verifikasi"
              step-verifikasi="keamanan"
            />
            <alert-module
              v-else
              :doc-data="keamanan"
            />
          </template>

          <template
            v-else-if="setStep === 'rekomendasi'"
          >
            <modul-rekomendasi-umum
              v-if="dataRekoSet && userData.group_id > 59 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="keamanan"
              step-rekomendasi="keamanan"
            />
            <summary-rekomendasi-ketua
              v-else-if="dataRekoSet && userData.group_id > 69 && userData.group_id < 80"
              :data-utama="utama"
              :detail-program="keamanan"
              step-rekomendasi="keamanan"
            />
          </template>
        </app-collapse-item>
      </app-collapse>
    </b-form>

    <template v-if="errorStat">
      <b-alert
        variant="danger"
        class="mt-2"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          {{ errorMsg }}
        </div>
      </b-alert>
    </template>

    <template
      v-if="(userData.group_id > 69 && userData.group_id < 81) && setStep === 'rekomendasi' && utama.status !== 'Draft'"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <h4>Pemberian Rekomendasi Selesai?</h4>
          <p v-if="!submitted">
            <b-button
              :to="{name: 'rekomendasi-awal' }"
              variant="outline-secondary"
              class="mx-25"
            >
              Belum
            </b-button>
            <b-button
              v-b-modal.modal-warning4
              variant="outline-success"
              class="mx-25"
            >
              Ya, Selesai
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>

      <b-modal
        id="modal-warning4"
        ref="modal-warning4"
        cancel-variant="outline-secondary"
        ok-variant="success"
        cancel-title="Batal"
        ok-title="Ya, Selesai!"
        modal-class="modal-warning"
        centered
        title="Attention!"
        @ok="simpanDataRekomendasi()"
      >
        <b-card-text>
          Dengan ini menyatakan bahwa proses pemberian rekomendasi telah sepenuhnya selesai?
        </b-card-text>
      </b-modal>
    </template>

    <template
      v-else-if="(userData.group_id > 59 && userData.group_id < 80) && setStep === 'verifikasi' && utama.status !== 'DRAFT'"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <h4>Verifikasi Teknis Selesai?</h4>
          <p v-if="!submitted">
            <b-button
              :to="{ name: 'verifikasi-teknis' }"
              variant="outline-secondary"
              class="mx-25"
            >
              Belum
            </b-button>
            <b-button
              v-b-modal.modal-warning3
              variant="outline-success"
              class="mx-25"
            >
              Ya, Selesai
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>

      <b-modal
        id="modal-warning3"
        ref="modal-warning3"
        cancel-variant="outline-secondary"
        ok-variant="success"
        cancel-title="Batal"
        ok-title="Ya, Selesai!"
        modal-class="modal-warning"
        centered
        title="Attention!"
        @ok="simpanDataVerifikasi()"
      >
        <b-card-text>
          Dengan ini menyatakan bahwa proses verifikasi teknis telah sepenuhnya selesai?
        </b-card-text>
      </b-modal>
    </template>

    <template
      v-else-if="(userData.group_id === 100 || userData.group_id === 101) && (utama.status === 'Draft' || utama.status === 'Perlu Perbaikan')"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <p v-if="!submitted">
            <b-button
              v-if="userData.group_id === 100"
              variant="outline-warning"
              class="mr-50"
              :to="{ name: 'pengajuan-surat-ubah', params: { id: utama.clearance_id } }"
            >
              Ubah Surat
            </b-button>
            <b-button
              variant="outline-warning"
              class="mr-50"
              :to="{ name: 'kegiatan-ubah-item', params: { id: this.$route.params.id } }"
            >
              Ubah / Lengkapi Data kegiatan
            </b-button>
            <b-button
              v-b-modal.modal-warning1
              variant="outline-success"
            >
              Simpan Sebagai Data Lengkap
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>
    </template>

    <template
      v-else-if="userData.group_id === 100 && utama.status === 'Draft Lengkap'"
    >
      <b-card class="mt-2">
        <b-card-text
          cols="12"
          class="text-center"
        >
          <p v-if="!submitted">
            <b-button
              variant="outline-warning"
              class="mr-50"
              :to="{ name: 'kegiatan-ubah-item', params: { id: this.$route.params.id } }"
            >
              Ubah / Lengkapi Data kegiatan
            </b-button>
            <b-button
              v-b-modal.modal-warning2
              variant="outline-success"
            >
              Submit Untuk Clearance
            </b-button>
          </p>
          <p v-else>
            <span class="text-success">Data Berhasil Disimpan</span>
          </p>
        </b-card-text>
      </b-card>
    </template>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning1"
      ref="modal-warning1"
      cancel-variant="outline-secondary"
      ok-variant="success"
      cancel-title="Batal"
      ok-title="Ya, Simpan!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @ok="simpanLengkap()"
    >
      <b-card-text>
        Menyatakan Bahwa Input Kegiatan Telah Lengkap?
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning2"
      ref="modal-warning2"
      cancel-variant="outline-secondary"
      ok-variant="success"
      cancel-title="Batal"
      ok-title="Ya, Lanjutkan!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @ok="ajukanClearance()"
    >
      <b-card-text>
        Ajukan Kegiatan Untuk Clearance?
      </b-card-text>
    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BModal, BCardText, BCard, BForm, BAlert,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import VuePdfApp from 'vue-pdf-app'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import AlertModule from './AlertModule.vue'
import ModelAplikasi from './DetailAplikasiReferensi.vue'
import ModelDokumen from './DetailDokumen.vue'
import ModelInfra from '../detail-kegiatan/KegiatanInfrastruktur.vue'
import ModelKeamanan from './DetailKeamanan.vue'
import ModelLayanan from './DetailLayanan.vue'
import ModelProbis from './DetailProbis.vue'

import ModulVerifikasi from '../../verifikasi-teknis/ModulVerifikasi.vue'
import ModulRekomendasiUmum from '../../rekomendasi/ModulRekomendasiUmum.vue'
import ModulRekomendasiClearance from '../../rekomendasi/ModulRekomendasiClearance.vue'

import SummaryRekomendasiKetua from '../../rekomendasi/SummaryRekomendasiKetua.vue'
import SummaryRekomendasiKetuaClearance from '../../rekomendasi/SummaryRekomendasiKetuaClearance.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BAlert,
    BForm,
    BCardText,
    BModal,
    BButton,
    BCard,
    AppCollapse,
    AppCollapseItem,
    AlertModule,
    ModelAplikasi,
    ModelDokumen,
    ModelInfra,
    ModelKeamanan,
    ModelLayanan,
    ModelProbis,
    ModulVerifikasi,
    ModulRekomendasiUmum,
    ModulRekomendasiClearance,
    SummaryRekomendasiKetua,
    SummaryRekomendasiKetuaClearance,
    VuePdfApp,
  },
  props: {
    setStep: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: getUserData(),
      mode: true,
      verifikasi: 0,
      rekomendasi: 0,
      submitted: false,
      dataRekoSet: false,
      fileDoc: '',
      errorStat: false,
      errorMsg: '',
      dataProgram: {},
      utama: {},
      program: {},
      dokumen: {},
      layanan: {},
      dataInfo: {},
      aplikasi: {},
      infra: {},
      keamanan: {},
    }
  },
  mounted() {
    this.$http.get('/clearance/preview', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.$route.params.id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.dataProgram = res.data.data

          if (this.dataProgram.data_utama) {
            this.utama = this.dataProgram.data_utama
          }
          if (this.dataProgram.program) {
            this.program = this.dataProgram.program
          }
          if (this.dataProgram.dokumen) {
            this.dokumen = this.dataProgram.dokumen
          }
          if (this.dataProgram.layanan) {
            this.layanan = this.dataProgram.layanan
          }
          if (this.dataProgram.data_info) {
            this.dataInfo = this.dataProgram.data_info
          }
          if (this.dataProgram.aplikasi) {
            this.aplikasi = this.dataProgram.aplikasi
          }
          if (this.dataProgram.infra) {
            this.infra = this.dataProgram.infra
          }
          if (this.dataProgram.keamanan) {
            this.keamanan = this.dataProgram.keamanan
          }
          this.dataRekoSet = true
        }
      })
  },
  methods: {
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    simpanLengkap() {
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/clearance/confirm-complete', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.submitted = true

            setTimeout(() => {
              this.$router.replace('/pengajuan')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    ajukanClearance() {
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/clearance/confirm-submit', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.submitted = true

            setTimeout(() => {
              this.$router.replace('/pengajuan')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    simpanDataVerifikasi() {
      // this.verifikasi += 1
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/verification/submit', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.submitted = true
            this.errorStat = false

            setTimeout(() => {
              this.$router.replace('/verifikasi-teknis')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    simpanDataRekomendasi() {
      this.rekomendasi += 1
      const formData = {
        clearance_id: this.utama.clearance_id,
        data_utama_id: this.utama.data_utama_id,
      }
      this.$http.post('/recommendation/submit', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.submitted = true

            setTimeout(() => {
              this.$router.replace('/rekomendasi-awal')
            }, 1200)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
